import React, { useEffect } from 'react';

const Cookie = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="">
            <div class="landing-page page-header" data-parallax="true"
                style={{ background: "rgb(208, 235, 231)", height: "", transform: "translate3d(0px, 91.3333px, 0px)" }}>
                {/* <!-- Headline --> */}
                <div class="container text-center introAnim">
                    <div class="row justify-content-center">
                        <div class="col-md-8 justify-content-center">
                            <h2 class="card-title font-weight-light"><br />Cookie Policy</h2>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="main main-raised fadePageCard pt-1">
                <div class="container mt-5 mb-5">

                    <ceres-cookie-policy title="I. About Cookies" description="
            <b>What are Cookies?</b><br/><br/>
                A cookie is a technology that allows us to remember information about you as a customer. Cookies are tiny 
                text files that store information on your access computer. Without cookies, every website and app simply 
                loses its memory each time you visit a new page or reopen an app. It has no idea who you are, what you've done, 
                or what you like. This can be fairly complicated as the user experience you are used to getting from Cerebranium 
                when using our website or app would cease to exist. Without this memory we, for example, can’t log you in as we 
                forgot who you are. Or we can’t show you the rights language settings because we don’t know where you are 
                coming from. So all these useful functionalities which makes the Cerebranium website enjoyable wouldn’t 
                be possible. Cookies are also used to know which of our marketing partners send you to our website. 
                If you click on a Cerebranium ad on another website, this website uses cookies to let us know that you’re 
                coming from them.<br/><br/>
                Cookies by themselves aren't really good or bad, so it's important to know what you can do with them and make your own decisions regarding your data.
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">I. About Cookies</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                <b>What are Cookies?</b><br /><br />
                                A cookie is a technology that allows us to remember information about you as a customer. Cookies are tiny
                                text files that store information on your access computer. Without cookies, every website and app simply
                                loses its memory each time you visit a new page or reopen an app. It has no idea who you are, what you've done,
                                or what you like. This can be fairly complicated as the user experience you are used to getting from Cerebranium
                                when using our website or app would cease to exist. Without this memory we, for example, can’t log you in as we
                                forgot who you are. Or we can’t show you the rights language settings because we don’t know where you are
                                coming from. So all these useful functionalities which makes the Cerebranium website enjoyable wouldn’t
                                be possible. Cookies are also used to know which of our marketing partners send you to our website.
                                If you click on a Cerebranium ad on another website, this website uses cookies to let us know that you’re
                                coming from them.<br /><br />
                                Cookies by themselves aren't really good or bad, so it's important to know what you can do with them and make your own decisions regarding your data.

                            </p>
                        </div>
                    </ceres-cookie-policy>


                    <ceres-cookie-policy title="II. What types of cookies exist?" description="
            At the end of your browser session, some of the Cookies we use will be automatically removed from your 
            hard drive (Session Cookies). We also use cookies, which stay on your hard drive after your browsing session 
            has ended for a set period of time (Persistent Cookies). The country and language you chose on your previous 
            visit to our Website will be recognized automatically on your next visit. These Persistent Cookies will be saved 
            on your hard drive and then removed by your browser after the specified time period has passed. Cookies from 
            third-party providers can also be included in this group (Third-Party Cookies).
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">II. What types of cookies exist?</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                At the end of your browser session, some of the Cookies we use will be automatically removed from your
                                hard drive (Session Cookies). We also use cookies, which stay on your hard drive after your browsing session
                                has ended for a set period of time (Persistent Cookies). The country and language you chose on your previous
                                visit to our Website will be recognized automatically on your next visit. These Persistent Cookies will be saved
                                on your hard drive and then removed by your browser after the specified time period has passed. Cookies from
                                third-party providers can also be included in this group (Third-Party Cookies).

                            </p>
                        </div>
                    </ceres-cookie-policy>

                    <ceres-cookie-policy title="III. Your decision" description="
            <b>What will you be asked to decide about and when?</b><br/><br/>
            When you visit our website or WebApp, or when you install our app, you will be 
            asked if you consent to the use of cookies in order to monitor which of our partners 
            sent you to our website and to show you relevant information. You need to actively 
            respond to this question in order to activate these cookies.
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">III. Your decision</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                <b>What will you be asked to decide about and when?</b><br /><br />
                                When you visit our website or WebApp, or when you install our app, you will be
                                asked if you consent to the use of cookies in order to monitor which of our partners
                                sent you to our website and to show you relevant information. You need to actively
                                respond to this question in order to activate these cookies.

                            </p>
                        </div>
                    </ceres-cookie-policy>

                    <ceres-cookie-policy title="IV. Which cookies are covered by your consent?" description="
            We are only asking for your consent to use marketing cookies. Cookies that enable us to improve the
             functionality and security of our Website/WebApp or App are necessary to provide you with our services. 
             We assume that you want us to remember you at your next login. We assume you want us to show you our 
             website in the language you are comfortable with. And we assume you want us to improve our performance 
             by e.g. letting us know how often you visited the Cerebranium website and to produce more content you 
             might be interested in, with this data.
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">IV. Which cookies are covered by your consent?</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                We are only asking for your consent to use marketing cookies. Cookies that enable us to improve the
                                functionality and security of our Website/WebApp or App are necessary to provide you with our services.
                                We assume that you want us to remember you at your next login. We assume you want us to show you our
                                website in the language you are comfortable with. And we assume you want us to improve our performance
                                by e.g. letting us know how often you visited the Cerebranium website and to produce more content you
                                might be interested in, with this data.

                            </p>
                        </div>
                    </ceres-cookie-policy>

                    <ceres-cookie-policy title="V. Which cookies can we use without your consent?" description="
            The usage of cookies which ensure the functionality and security of our Website, Web App or App is justified in 
            accordance with Art. 6 (1) f) GDPR, which is why your explicit consent for using those cookies is not necessary.
            Since we assume that it is also in your interest for our Website, Web page, and App to be secured against breakdowns 
            or losses that could compromise the confidentiality of your data, we use cookies to ensure the functionality and 
            security of our products.
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">V. Which cookies can we use without your consent?</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                The usage of cookies which ensure the functionality and security of our Website, Web App or App is justified in
                                accordance with Art. 6 (1) f) GDPR, which is why your explicit consent for using those cookies is not necessary.
                                Since we assume that it is also in your interest for our Website, Web page, and App to be secured against breakdowns
                                or losses that could compromise the confidentiality of your data, we use cookies to ensure the functionality and
                                security of our products.

                            </p>
                        </div>
                    </ceres-cookie-policy>

                    <ceres-cookie-policy title="VI. What kind of data is collected that is necessary for a safe and efficient product?" description="
                As explained under III. above, we are automatically using cookies which are necessary for the functionality 
                and security of our product and are not asking for your consent. Thereby we are collecting the following data:
            <div class='text-left'>
                <ul>
                    <li>IP address</li>
                    <li>Date and time of the request</li>
                    <li>Time zone difference to Greenwich Mean Time (GMT)</li>
                    <li>Content of the request (specific page)</li>
                    <li>Access status/http status code</li>
                    <li>Volume of data transferred</li>
                    <li>Website (from which the request comes)</li>
                    <li>Information of the Browser</li>
                    <li>Information of Operating system (e.g. version or producer)</li>
                    <li>Information of the device</li>
                    <li>Language and version of the browser software</li>
                    <li>Referrer (the last visited website, if available)</li>
                </ul>
            </div>
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">VI. What kind of data is collected that is necessary for a safe and efficient product?</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                As explained under III. above, we are automatically using cookies which are necessary for the functionality
                                and security of our product and are not asking for your consent. Thereby we are collecting the following data:
                            </p><div class="text-left">
                                <ul>
                                    <li>IP address</li>
                                    <li>Date and time of the request</li>
                                    <li>Time zone difference to Greenwich Mean Time (GMT)</li>
                                    <li>Content of the request (specific page)</li>
                                    <li>Access status/http status code</li>
                                    <li>Volume of data transferred</li>
                                    <li>Website (from which the request comes)</li>
                                    <li>Information of the Browser</li>
                                    <li>Information of Operating system (e.g. version or producer)</li>
                                    <li>Information of the device</li>
                                    <li>Language and version of the browser software</li>
                                    <li>Referrer (the last visited website, if available)</li>
                                </ul>
                            </div>

                            <p></p>
                        </div>
                    </ceres-cookie-policy>

                    <ceres-cookie-policy title="VII. Which cookies are covered by your consent?" description="
            We are only asking for your consent to use marketing cookies. Cookies that enable us to improve the
            functionality and security of our Website/WebApp or App are necessary to provide you with the product the
            world loves to use. We assume that you want us to remember you at your next login. We assume you want us
            to show you our website in the language you understand. And we assume you want us to improve our
            performance by e.g. letting us know how often you visited a specific Cerebranium website and with this information to
            produce more content you might be interested in
            <div class=&quot;row text-justify mt-0&quot;>
                <div class=&quot;col m-1 ml-4&quot;>
                    <h3 class=&quot;title text-left&quot;>1. Use of Google Analytics</h3>
                </div>
                <p class=&quot;pl-5 pr-5&quot;>
                    Our Website and our Web-App use Google Analytics web analysis services of Google
                    Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (hereinafter: “Google”). Google Analytics
                     use so-called “Cookies”; text files which are stored on your computer/smartphone and
                    which enable analysis of your use of our Website and Web-App. <br/><br/>
                    The information generated by the Cookie about
                    your use of our Website and Web-App is generally transmitted to a Google server in the USA and stored there.
                    However, within member states of the European Union or in other parties to the Agreement on the European
                    Economic Area, your IP address will be previously shortened by Google on our Website and Web-App.<br/><br/>
                    Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and abbreviated
                    there. Google will use this information on behalf of the operator of this Website/App to evaluate your use of the
                    Website, in order to compile reports about the Website activities and to render further services to the Website
                    operator/App operator connected with the Website use/app use and internet use. The IP address transmitted
                    by your browser within the framework of Google Analytics will not be merged with other data from Google.
                    <br/><br/> We hereby point out that, on our Website, Google Analytics are expanded with the code “gat._anonymizeIp();” in
                    order to guarantee an anonymised collection of IP addresses (so-called IP Masking).
                    <br/><br/>
                    You can find more detailed information about this at:<a href=&quot;http://google.com/intl/de/analytics/privacyoverview.html&quot; target=&quot;_blank&quot;> here</a>.
                </p>
              </div>
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">VII. Which cookies are covered by your consent?</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                We are only asking for your consent to use marketing cookies. Cookies that enable us to improve the
                                functionality and security of our Website/WebApp or App are necessary to provide you with the product the
                                world loves to use. We assume that you want us to remember you at your next login. We assume you want us
                                to show you our website in the language you understand. And we assume you want us to improve our
                                performance by e.g. letting us know how often you visited a specific Cerebranium website and with this information to
                                produce more content you might be interested in
                            </p><div class="row text-justify mt-0">
                                <div class="col m-1 ml-4">
                                    <h3 class="title text-left">1. Use of Google Analytics</h3>
                                </div>
                                <p class="pl-5 pr-5">
                                    Our Website and our Web-App use Google Analytics web analysis services of Google
                                    Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (hereinafter: “Google”). Google Analytics
                                    use so-called “Cookies”; text files which are stored on your computer/smartphone and
                                    which enable analysis of your use of our Website and Web-App. <br /><br />
                                    The information generated by the Cookie about
                                    your use of our Website and Web-App is generally transmitted to a Google server in the USA and stored there.
                                    However, within member states of the European Union or in other parties to the Agreement on the European
                                    Economic Area, your IP address will be previously shortened by Google on our Website and Web-App.<br /><br />
                                    Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and abbreviated
                                    there. Google will use this information on behalf of the operator of this Website/App to evaluate your use of the
                                    Website, in order to compile reports about the Website activities and to render further services to the Website
                                    operator/App operator connected with the Website use/app use and internet use. The IP address transmitted
                                    by your browser within the framework of Google Analytics will not be merged with other data from Google.
                                    <br /><br /> We hereby point out that, on our Website, Google Analytics are expanded with the code “gat._anonymizeIp();” in
                                    order to guarantee an anonymised collection of IP addresses (so-called IP Masking).
                                    <br /><br />
                                    You can find more detailed information about this at:<a href="http://google.com/intl/de/analytics/privacyoverview.html" target="_blank"> here</a>.
                                </p>
                            </div>

                            <p></p>
                        </div>
                    </ceres-cookie-policy>

                    <ceres-cookie-policy title="VIII. How can you revoke your consent?" description="
            You have the right to revoke your consent to use marketing cookies at any time. 
            If you do so, we will not process your data for these purposes anymore. The data processing that has 
            been performed until receipt of your revocation stays legitimate. For your revocation there is no specific 
            formal requirement. You can change your Cookie Settings at any time by clicking <a class='update-cookie2 text-primary'>here</a>.
            ">
                        <div class="row text-justify mt-5">
                            <div class="col m-1">
                                <h2 class="title text-left p-0">VIII. How can you revoke your consent?</h2>
                            </div>
                            <p class="p-2 pl-4 pr-4">

                                You have the right to revoke your consent to use marketing cookies at any time.
                                If you do so, we will not process your data for these purposes anymore. The data processing that has
                                been performed until receipt of your revocation stays legitimate. For your revocation there is no specific
                                formal requirement. You can change your Cookie Settings at any time by clicking <a class="update-cookie2 text-primary">here</a>.

                            </p>
                        </div>
                    </ceres-cookie-policy>

                </div>
            </div>
        </div >
    )
}

export default Cookie;