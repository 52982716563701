import axios from "axios";
import { API_BASE_URL } from "./api_constants";

export default {
  enquiryCrud: {
    addEnquiry: (data) =>
      axios.post(API_BASE_URL + `/web/contact_us`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API ADD ENQUIRY=====> res", res);
        return res.data;
      })
  },
  blogCrud: {
    getAll: () =>
      axios.get(API_BASE_URL + `/web/blogs/get`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("ALL BLOGS FETCHED=====> ", res);
        return res.data;
      }),
    getOne: (data) =>
      // axios.get(API_BASE_URL + `/web/blogs/get/single`,data, {
      axios.get(API_BASE_URL + `/web/blogs/get/single/?blogs_id=${data}`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("BLOG FETCHED=====> ", res);
        console.log("BLOG DATA API=====> ", data);
        return res.data;
      }),
    getFeatured: () =>
      axios.get(API_BASE_URL + `/web/blogs/get_featured`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("API GET FEATURE=====> res", res);
        return res.data;
      }),
  },
  careerCrud: {
    getAll: () =>
      axios.get(API_BASE_URL + `/web/careers/get`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("ALL CAREERS FETCHED=====> res", res);
        return res.data;
      })
  },

  teamCrud: {
    getAll: () =>
      axios.get(API_BASE_URL + `/web/team_members/get`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("ALL TEAMS FETCHED=====> res", res);
        return res.data;
      })
  },

  csrCrud: {
    getAll: () =>
      axios.get(API_BASE_URL + `/web/csr/get`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("ALL CSR FETCHED=====> res", res);
        return res.data;
      })
  },

  eventCrud: {
    getAll: () =>
      axios.get(API_BASE_URL + `/web/events/get`, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        console.log("ALL EVENTS FETCHED=====> res", res);
        return res.data;
      })
  },

}