import React, { useEffect, useState } from "react";
import api from "../api";
import moment from "moment";

const EventsCsr = () => {
  const [eventList, setEventList] = useState([]);
  const [csrList, setCsrList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getEventList();
  }, []);

  const getCsrList = () => {
    api.csrCrud
      .getAll()
      .then((result) => {
        console.log("csr get method ", result);
        if (result.status === 1) {
          setCsrList(result.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEventList = () => {
    api.eventCrud
      .getAll()
      .then((result) => {
        console.log("event get method ", result);
        if (result.status === 1) {
          const newArr = result.data.sort((a, b) => {
            let dateA = new Date(
              moment(a.event_date).format("YYYY-MM-DD").toString()
            );
            let dateB = new Date(
              moment(b.event_date).format("YYYY-MM-DD").toString()
            );
            return dateB - dateA;
          });
          console.log("latest date eeven ", newArr);
          setEventList(newArr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div class="event-page-hero">
        <div class="event-hero-inner">
          <div class="page-heading ">
            <div class="sec-title-big sec-margin">
              <h2 class="text-center">Events & CSR</h2>
            </div>
            <p class="text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor <br />
              incididunt Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- event hero End  --> */}
      <div class="event-card-main">
        <div class="container">
          <div class="event-card-inner ">
            <div class="event-tab-link">
              <ul class="nav nav-tabs event-tabs" id="myTab" role="tablist">
                <li class="nav-item" onClick={() => getEventList()}>
                  <a
                    class="nav-link active"
                    id="event-tab"
                    data-toggle="tab"
                    href="#event"
                    role="tab"
                    aria-controls="event"
                    aria-selected="true"
                  >
                    Events
                  </a>
                </li>
                <li class="nav-item" onClick={() => getCsrList()}>
                  <a
                    class="nav-link"
                    id="csr-tab"
                    data-toggle="tab"
                    href="#csr"
                    role="tab"
                    aria-controls="csr"
                    aria-selected="false"
                  >
                    CSR
                  </a>
                </li>
              </ul>
            </div>
            <div class="event-tab-main">
              <div class="tab-content event-tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="event"
                  role="tabpanel"
                  aria-labelledby="event-tab"
                >
                  <div class="event-tab-inner">
                    {eventList.map((data) => {
                      return (
                        <div class="event-card row align-items-center">
                          <div class="col-lg-6 col-md-12 event-left">
                            <div class="event-detail">
                              <span>
                                {moment(data.event_date).format("DD-MM-YYYY")}
                              </span>
                              <h3>{data.event_title}</h3>
                              <p>{data.event_description} </p>
                              <button
                                class="btn-book"
                                onClick={() => {
                                  window.open(data.button_url);
                                }}
                              >
                                {data.button_text}
                              </button>
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-12 event-right">
                            <div class="event-img">
                              <img
                                src={data.image_path}
                                alt="imakge"
                                height="360px"
                                width="507px"
                                style={{ borderRadius: "2%" }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* --------------For CSR List ----------------- */}

                <div
                  class="tab-pane fade"
                  id="csr"
                  role="tabpanel"
                  aria-labelledby="csr-tab"
                >
                  <div class="event-tab-inner">
                    {csrList.map((data) => {
                      return (
                        <div class="event-card row align-items-center">
                          <div class="col-lg-6 col-md-12 event-left">
                            <div class="event-detail">
                              <span>{data.institute_name}</span>
                              <h3>{data.event_name}</h3>
                              <p>{data.event_description} </p>
                              <button
                                class="btn-book"
                                onClick={() => {
                                  window.open(data.button_url);
                                }}
                              >
                                {data.button_text}
                              </button>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 event-right">
                            <div class="event-img">
                              <img
                                src={data.image_url}
                                class="img-fluid"
                                alt="himage"
                                height="360px"
                                width="507px"
                                style={{ borderRadius: "2%" }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsCsr;
