import React, { useEffect } from "react";

const Imprint = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <h1 style={{ fontSize: "50px" }}>
        <b>Imprint</b>
      </h1>
      <br />
      <p style={{ fontSize: "large", fontWeight: "500", color: "#8B9195" }}>
        Providers pursuant to sec. 5 (1) German Telemedia Act (Telemediengesetz
        - TMG):
      </p>
      <p style={{ fontSize: "large", fontWeight: "500" }}>
        Cerebranium GmbH
        <br />
        Address:{" "}
        <a
          href=""
          style={{
            fontSize: "large",
            fontWeight: "500",
            color: "#8B9195",
            textDecoration: "none",
            pointerEvents: "none",
          }}
        >
          Papelallee 78/79, 10437 Berlin, Germany
        </a>
        <br />
        Commercial register number:{" "}
        <a
          href=""
          style={{
            fontSize: "large",
            fontWeight: "500",
            color: "#8B9195",
            textDecoration: "none",
            pointerEvents: "none",
          }}
        >
          Amtsgericht Charlottenburg (Berlin) - HRB 228046 B
        </a>
        <br />
        VAT-ID:{" "}
        <a
          href=""
          style={{
            fontSize: "large",
            fontWeight: 500,
            color: "#8B9195",
            textDecoration: "none",
            pointerEvents: "none",
          }}
        >
          DE341629986
        </a>
        <br />
        Managing Director:{" "}
        <a
          href=""
          style={{
            fontSize: "large",
            fontWeight: 500,
            color: "#8B9195",
            textDecoration: "none",
            pointerEvents: "none",
          }}
        >
          Omkar Pimple
        </a>
        <br />
        Email:{" "}
        <a
          href=""
          style={{
            fontSize: "large",
            fontWeight: 500,
            color: "#8B9195",
            textDecoration: "none",
            pointerEvents: "none",
          }}
        >
          welcome@cerebranium.com
        </a>
        <br />
      </p>
    </div>
  );
};

export default Imprint;
