import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router";
import api from "../api";
import ModalVideo from "react-modal-video";
import YouTube from "react-youtube";

const Home = () => {
  const [blogData, setBlogData] = useState([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [imgShow, setimgShow] = useState("flex");
  useEffect(() => {
    window.scrollTo(0, 0);
    api.blogCrud.getAll().then((res) => {
      if (res.status === 1) {
        console.log(res);
        let arr = res.data.reverse();
        setBlogData(arr);
      } else {
        console.log(res);
      }
    });
  }, []);

  return (
    <div className={open && "blur-bg"}>
      {/* <!-- hero section Start  --> */}
      <div className="hero-section">
        <div className="container h-100" id={open && "blur-bg"}>
          <div className="hero-inner  d-flex align-items-center">
            <div className="hero-left col-lg-6 col-md-12">
              <div className="hero-text">
                <h1>Powering the Future of Engagement-Driven Education</h1>
                <p>
                  We help education providers with achieving high levels of
                  Learner Engagement, Employability, and Enrolment with
                  Data-driven Governance.
                </p>
                <a href="#">
                  <button
                    className="btn-book"
                    onClick={() => {
                      window.open(
                        "https://calendly.com/omkar-c9m/promexa?back=1",
                        "_blank"
                      );
                    }}
                  >
                    Book a demo
                  </button>
                </a>
              </div>
            </div>
            <div className="hero-right col-lg-6 col-md-12">
              <div className="assets/images/hero-img">
                <img
                  src="assets/images/hero-img.png"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- hero section End  -->
	   <!-- remote section Start  --> */}

      <div className="remote-section section">
        <div className="container ">
          <div className="sec-title">
            <h2>Promexa — Engagement-Driven Education For Everyone</h2>
            <p className="color-black">
              Promexa helps you improve the health of your learners' educational
              journeys and instructors' content, keeping learner engagement
              centre stage.
            </p>
          </div>
          {/* {open && (
            <div className="youtube-position">
              <center>
                <YouTube videoId="uAsoG4yobVY" className="youtubePopup" />
              </center>
            </div>
          )}
          <div
            className="remote-inner"
            style={{ position: "relative", display: imgShow }}
          >
            <div className="remote-img">
              <img
                src="assets/images/remote-mobile.png"
                alt="imaoge"
                className="img-fluid"
              />
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
                setimgShow("none");
              }}
              className="watch d-flex align-items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12L10.5 9V15L15 12Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.25 12C2.25 14.7895 2.5382 16.4263 2.75729 17.2675C2.81585 17.4972 2.92832 17.7097 3.08537 17.8873C3.24241 18.0649 3.43952 18.2026 3.66037 18.2888C6.79883 19.4949 12 19.4618 12 19.4618C12 19.4618 17.2011 19.4949 20.3396 18.2888C20.5604 18.2026 20.7576 18.0649 20.9146 17.8873C21.0717 17.7097 21.1841 17.4972 21.2427 17.2675C21.4618 16.4263 21.75 14.7896 21.75 12C21.75 9.21039 21.4618 7.57368 21.2427 6.73248C21.1841 6.50273 21.0717 6.29025 20.9146 6.11264C20.7576 5.93502 20.5605 5.79738 20.3396 5.71113C17.2012 4.50506 12 4.53818 12 4.53818C12 4.53818 6.79888 4.50506 3.66039 5.71112C3.43955 5.79737 3.24244 5.93501 3.08539 6.11262C2.92834 6.29024 2.81588 6.50272 2.75731 6.73246C2.53821 7.57366 2.25 9.21039 2.25 12Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Watch : Promexa in 90 Seconds
            </div>
          </div>*/}
        </div>
      </div> 

      {/* <!-- remote section end  -->
	 <!-- help section Start  --> */}
      <div className="help-section section-bottom">
        <div className="container ">
          <div className="sec-title">
            <h2>How Does Promexa Help You? </h2>
          </div>
          <div className="platform-inner row">
            <div className="col-lg-4 col-md-12">
              <div className="platform-card position-relative">
                <h4>Education Providers</h4>
                <p style={{ height: 168 }}>
                  Lead the way in the new education landscape by staying
                  informed about holistic organization health - all the way from
                  the learning journey of each individual, to the overall
                  performance of the content for all your courses.
                </p>
                <div className="p-no">01</div>

                <a href="/promexa" className="learn-more-btn">
                  <button className="btn-book">Learn More</button>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="platform-card position-relative">
                <h4>Teachers</h4>
                <p style={{ height: 168 }}>
                  Achieve improved learning outcomes by understanding areas of
                  improvement for each of your learners as well as your content
                  - with massive savings on time and effort in your
                  day-to-day-operations!
                </p>
                <div className="p-no">02</div>

                <a href="/promexa" className="learn-more-btn">
                  <button className="btn-book">Learn More</button>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="platform-card position-relative">
                <h4>Learners</h4>
                <p style={{ height: 168 }}>
                  Proceed successfully on the path to mastery by understanding
                  your key strengths and acting effectively on your improvement
                  areas!
                </p>
                <div className="p-no">03</div>
                <a href="/promexa" className="learn-more-btn">
                  <button className="btn-book">Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- help section end  -->
	  	<!-- challenge section Start  --> */}
      <div className="challenge-section section-bottom">
        <div className="container ">
          <div className="challenge-inner row">
            <div className="col-lg-6 col-md-12 challenge-left">
              <div className="sec-title">
                <h2 className="text-left">
                  Key Challenges & Risks For Education Providers Today
                </h2>
              </div>
              <div className="step-inner">
                <div className="step-main">
                  <div className="step-no-copy">1</div>
                  <div className="step-des">
                    <h4>Low Learner Engagement</h4>
                    <ul className="list-style-bullet">
                      <li>
                        <p>Limited or no visibility of learning goals</p>
                      </li>
                      <li>
                        <p>
                          Disengagement due to lack of personalized education
                          journeys
                        </p>
                      </li>
                      <li>
                        <p>
                          Subpar employability and alarming learner dropout
                          rate, which are key success indicators for educational
                          programs
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="step-main">
                  <div className="step-no">2</div>
                  <div className="step-des">
                    <h4>Low Adaptability To Rapid Changes</h4>
                    <ul className="list-style-bullet">
                      <li>
                        <p>Resource intensive decision-making processes</p>
                      </li>
                      <li>
                        <p>
                          Scarce availability of relevant data about learning
                          journeys leads to uninformed decision-making
                        </p>
                      </li>
                      <li>
                        <p>
                          No time and space to explore new tools and methods
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 challenge-right">
              <div className="challenge-img position-relative d-flex justify-content-center">
                <img
                  src="assets/images/mobile-screen2.png"
                  alt="images"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- challenge section End  -->
		<!-- challenge section Start2  --> */}
      <div className="challenge-section section-bottom">
        <div className="container ">
          <div className="challenge-inner row">
            <div className="col-lg-6 col-md-12 challenge-left">
              <div className="sec-title">
                <h2 className="text-left">
                  How Does Promexa Help You Overcome These Challenges?
                </h2>
              </div>
              <div className="step-inner">
                <div className="step-main">
                  <div className="step-no-copy">1</div>
                  <div className="step-des">
                    <h4>Improve Learning Outcomes With Reliable Data</h4>

                    <ul className="list-style-bullet">
                      <li>
                        <p>
                          Measure key learning metrics reliably and efficiently
                        </p>
                      </li>
                      <li>
                        <p>
                          Identify areas of improvement in learning journeys and
                          act on them early
                        </p>
                      </li>
                      <li>
                        <p>
                          Personalize education for your learners to optimize
                          their learning journeys
                        </p>
                      </li>
                      <li>
                        <p>
                          Make learning goals transparent so learners can
                          improve on them effectively
                        </p>
                      </li>
                      <li>
                        <p>Achieve high learner engagement and performance</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="step-main">
                  <div className="step-no">2</div>
                  <div className="step-des">
                    <h4>Enhance Operational Efficiency With Technology</h4>
                    <ul className="list-style-bullet">
                      <li>
                        <p>
                          Automate repetitive and tedious tasks using nimble
                          data management processes and reliable technology
                        </p>
                      </li>
                      <li>
                        <p>
                          Simplify your assessment management using our
                          decentralized assessment platform
                        </p>
                      </li>
                      <li>
                        <p>
                          Create informative feedback loops to make fast
                          decisions on learners' areas of improvement and course
                          quality
                        </p>
                      </li>
                      <li>
                        <p>Achieve high quality learning outcomes at scale</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="step-main">
                  <div className="step-no">3</div>
                  <div className="step-des">
                    <h4>
                      We see the result of this approach manifest daily with our
                      partners:{" "}
                    </h4>
                    <ul className="list-style-bullet">
                      <li>
                        <p>Fast & accurate decisions on education quality</p>
                      </li>
                      <li>
                        <p>High Learner Engagement</p>
                      </li>
                      <li>
                        <p>Improved Learner Employability</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 challenge-right">
              <div className="challenge-img position-relative d-flex justify-content-center">
                <img
                  src="assets/images/mobile-screen2.png"
                  alt="images"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- challenge section End2  -->
  <!-- platform section Start  --> */}
      <div className="platform-section section-bottom">
        <div className="container">
          <div className="sec-title">
            <h2 className="">Promexa Is Built On...</h2>
          </div>
          <div className="platform-inner row">
            <div className="col-lg-4 col-md-12">
              <div className="platform-card position-relative">
                <h4>Scientific & Market Research</h4>
                <p>
                  Our data science & AI research published in Springer, and our
                  market research representing stakeholders from 124 education
                  providers.
                </p>
                <div className="p-no">01</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="platform-card position-relative">
                <h4>GDPR-Compliant Technology</h4>
                <p>
                  Mission critical, state-of-the-art information security
                  protocols applied from our extensive experience in finance
                  technology.
                </p>
                <div className="p-no">02</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="platform-card position-relative">
                <h4>A Mobile-first Vision</h4>
                <p>
                  Harnessing the full potential of mobile platforms, augmented
                  by our background in serving 5 million+ mobile users across
                  180 countries.
                </p>
                <div className="p-no">03</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- platform section End  -->	
	 <!-- service slider section Start  --> */}
      <div className="serv-slider-section section">
        <div className="container">
          <div className="slider-top sec-margin d-flex align-items-center justify-content-between">
            <div className="sec-title mb-0">
              <h2 className="text-left">
                Hear Directly What
                <br /> Our Partners Are Saying.{" "}
              </h2>
            </div>
            <div className="slick-arrows d-flex align-items-center">
              <button className="slick-arrow-left ">
                <svg
                  width="11"
                  height="18"
                  viewBox="0 0 11 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16233 16.0249L9.13733 9.04989L2.16233 2.07489"
                    stroke="#0A043C"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button className="slick-arrow-right">
                <svg
                  width="11"
                  height="18"
                  viewBox="0 0 11 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16233 16.0249L9.13733 9.04989L2.16233 2.07489"
                    stroke="#0A043C"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="slider-inner">
            <div className="serv-slider">
              <div className="serv-card-main">
                <div className="serv-card">
                  <div className="serv-para">
                    <p>
                      “We will continue to use Promexa for deep insights on the
                      education journeys of each of our learners. It has helped
                      our staff improve education quality significantly over the
                      past year while saving a valuable amount of time, effort,
                      and cost - all fully remotely.”
                    </p>
                  </div>
                  <div className="serv-bottom d-flex align-items-center ">
                    <div className="user-img">
                      <img
                        src="assets/images/SavitaThakur_SMVITA(1).png"
                        className="img-fluid"
                        height="63px"
                        width="168px"
                        alt="imapge"
                      />
                    </div>
                    <div className="user-name">
                      <h5>Ms. Savita Thakur</h5>
                      <span>
                        Director, Shriram Mantri Info Tech Academy SM VITA CDAC
                        Training Centre
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="serv-card-main">
                <div className="serv-card">
                  <div className="serv-para">
                    <p>
                      “Many of our learners only have sporadic access to 2G
                      networks. Promexa helps us enrich our education quality
                      with the insights that it provides. We have had a seamless
                      and stress-free experience for all our learners and staff
                      members, no matter where they are located.”
                    </p>
                  </div>
                  <div className="serv-bottom d-flex align-items-center">
                    <div className="user-img">
                      <img
                        src="assets/images/DrBhageshSankhe_SAS.png"
                        className="img-fluid"
                        height="74px"
                        width="144px"
                        alt="imapge"
                      />
                    </div>
                    <div className="user-name">
                      <h5>Dr. Bhagesh Sankhe</h5>
                      <span>
                        Co-founder and Director, SAS Institute of Management
                        Studies
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="serv-card-main">
                <div className="serv-card">
                  <div className="serv-para">
                    <p>
                      “It was shocking to see what we were missing before we
                      started using the insights that Promexa provides. It is a
                      very fresh approach to ensuring quality for high-stakes
                      entrance exams. The team at Cerebranium has been
                      absolutely fantastic with their support throughout our
                      journey.”
                    </p>
                  </div>
                  <div className="serv-bottom d-flex align-items-center ">
                    <div className="user-img">
                      <img
                        src="assets/images/VibhaHasija_NN.png"
                        height="78px"
                        width="160px"
                        className="img-fluid"
                        alt="impage"
                      />
                    </div>
                    <div className="user-name">
                      <h5>Ms. Vibha Hasija</h5>
                      <span>
                        Head of Department, Nirmala Niketan College Of Home
                        Science
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- service slider section End  -->
	 <!-- library section Start  --> */}
      <div className="library-section section">
        <div className="container">
          <div className="library-box">
            <div className="library-top d-flex align-items-center justify-content-between">
              <div className="sec-title">
                <h2 className="text-left">Cerebranium Library</h2>
                <p className="text-left">
                  An archive of what's happening at Cerebranium. <br />
                  Read and grow with us!{" "}
                </p>
              </div>
              <Link to="/blogs">
                <a href="#" className="article-btn">
                  Read all articles
                </a>
              </Link>
            </div>
            <div className="article-inner row">
              {blogData.slice(0, 3).map((data, idx) => {
                return (
                  <div className="col-lg-4 col-md-12 article-card">
                    <div
                      className="article-img"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/blog-detail",
                          state: {
                            status: data.blogs_id,
                          },
                        });
                      }}
                    >
                      <img
                        src={data.blog_image}
                        className="img-fluid"
                        alt="image"
                      />
                    </div>
                    <div className="article-des">
                      <span className="article-date">
                        {moment(data.date).format("DD-MM-YYYY")}
                      </span>
                      <h4
                        className="article-title"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push({
                            pathname: "/blog-detail",
                            state: {
                              status: data.blogs_id,
                            },
                          });
                        }}
                      >
                        {data.blog_title}
                      </h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- library section End  --> */}

      {/* <!-- bottom section Start  --> */}
      <div className="bottom-container section-bottom">
        <div className="container">
          <div className="bottom-inner row">
            <div className="col-lg-5 col-md-12">
              <div
                className="bottom-card home-bottom-card d-flex align-items-end w-100"
                style={{
                  backgroundImage: "url(assets/images/bottom-card3.png)",
                }}
              >
                <a
                  href="/about"
                  className="visit-link d-flex align-items-center justify-content-between w-100"
                >
                  <span>Know more about us</span>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M16.7573 20.2426L20.9999 15.9999L16.7573 11.7573"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 16H21"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div
                className="bottom-card home-bottom-card d-flex align-items-end w-100 mb-0"
                style={{
                  backgroundImage: "url(assets/images/bottom-card4.png)",
                }}
              >
                <a
                  href="/career"
                  className="visit-link d-flex align-items-center justify-content-between w-100"
                >
                  <span>Join our team</span>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M16.7573 20.2426L20.9999 15.9999L16.7573 11.7573"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 16H21"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 right-card">
              <div className="sec-title ml-5">
                <br />
                <h2 className="text-left">
                  Dive Into Any Section To Know More
                </h2>
              </div>
              <div className="bottom-big-card">
                <h5>
                  State of Digitization in Education and <br />
                  Examinations - India
                </h5>
                <p>
                  A snapshot of the transformation of education <br /> through
                  the eyes of the stakeholders - institutes <br />
                  and students - 2021
                </p>
                <a
                  target="_blank"
                  href="https://firebasestorage.googleapis.com/v0/b/cerebranium.appspot.com/o/Digital%20Education%20India%202021%20-%20Report.pdf?alt=media&token=0692ddbc-e56e-496f-9fed-ec45c8a1ef5e"
                  className="visit-link d-flex align-items-center justify-content-between w-100"
                >
                  <span>Read our report</span>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M16.7573 20.2426L20.9999 15.9999L16.7573 11.7573"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 16H21"
                      stroke="#04B8AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- bottom section End  -->*/}
    </div>
  );
};

export default Home;
