import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import api from "../api";
import moment from "moment";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

const Blogs = () => {
  const [blogData, setBlogData] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [blogList, setBlogList] = useState(0);
  const [sizePerPage, SetSizePerPage] = useState(3);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    api.blogCrud.getAll().then((res) => {
      if (res.status === 1) {
        setBlogData(res.data);
        setBlogList(res.data);
        setPageCount(res.data.length);
      } else {
        console.log(res);
      }
    });

    api.blogCrud
      .getFeatured()
      .then((result) => {
        if (result.status === 1) {
          setFeatured(result.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const changeCurrentPage = (numPage) => {
    setCurrentPage(numPage);
  };

  return (
    <div>
      {/* <!--Navigation End -->
      <!-- contact page hero Start  --> */}
      <div class="blog-page-hero">
        <div class="blog-inner">
          <div class="page-heading ">
            <div class="sec-title-big sec-margin">
              <h2 class="text-center">Cerebranium Library</h2>
            </div>
            <p class="text-center">
              A glimpse into the people, beliefs, and ideas that move us.
            </p>
          </div>
        </div>
        {featured && (
          <div class="blog-main section">
            <div class="container">
              <div class="blog-hero-inner  d-flex align-items-center">
                <div class="hero-left col-lg-6 col-md-12">
                  <div class="hero-text">
                    <span>Featured Blog</span>
                    <h1 class="f-54">{featured.blog_title}</h1>
                    <p class="color-black">{featured.short_description} </p>
                    <button
                      class="btn-book"
                      onClick={() => {
                        history.push({
                          pathname: "/blog-detail",
                          state: {
                            status: featured.blogs_id,
                          },
                        });
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
                <div class="hero-right col-lg-6 col-md-12">
                  <div class="article-img">
                    <img
                      src={featured.blog_image}
                      alt="featuredimage"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <!-- contact hero End  -->
	  <!--media section Start  --> */}
      <div class="media-section-container">
        <div class="container">
          <div class="page-heading sec-margin">
            <div class="sec-title-big ">
              <h2 class="text-center">Cerebranium in Media</h2>
            </div>
          </div>
          <div class="media-article ml-4">
            <div class="article-inner row">
              {blogList &&
                blogList.map((data, index) => {
                  if (currentPage == "1") {
                    if (index <= sizePerPage * currentPage - 1) {
                      return (
                        <div
                          class="col-lg-4 col-md-12 article-card mb-3"
                          key={index}
                        >
                          <div
                            class="article-img"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push({
                                pathname: "/blog-detail",
                                state: {
                                  status: data.blogs_id,
                                },
                              });
                            }}
                          >
                            <img
                              src={data.blog_image}
                              class="img-fluid"
                              alt="blogimage"
                            />
                          </div>

                          <div class="article-des">
                            <span class="article-date">
                              {moment(data.date).format("DD-MM-YYYY")}
                            </span>
                            <h4
                              class="article-title"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push({
                                  pathname: "/blog-detail",
                                  state: {
                                    status: data.blogs_id,
                                  },
                                });
                              }}
                            >
                              {data.blog_title}
                            </h4>
                            <p
                              className="mb-4"
                              style={{ lineHeight: "revert" }}
                            >
                              {data.short_description}{" "}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    if (
                      index >= (sizePerPage - 1) * currentPage - 1 &&
                      index <= sizePerPage * currentPage - 1
                    ) {
                      return (
                        <div
                          class="col-lg-4 col-md-12 article-card mb-3"
                          key={index}
                        >
                          <div
                            class="article-img"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push({
                                pathname: "/blog-detail",
                                state: {
                                  status: data.blogs_id,
                                },
                              });
                            }}
                          >
                            <img
                              src={data.blog_image}
                              class="img-fluid"
                              alt="blogimage"
                            />
                          </div>

                          <div class="article-des">
                            <span class="article-date">
                              {moment(data.date).format("DD-MM-YYYY")}
                            </span>
                            <h4
                              class="article-title"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push({
                                  pathname: "/blog-detail",
                                  state: {
                                    status: data.blogs_id,
                                  },
                                });
                              }}
                            >
                              {data.blog_title}
                            </h4>
                            <p
                              className="mb-4"
                              style={{ lineHeight: "revert" }}
                            >
                              {data.short_description}{" "}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="pagination-div">
        <Pagination
          currentPage={currentPage}
          totalSize={blogData.length}
          sizePerPage={sizePerPage}
          changeCurrentPage={changeCurrentPage}
          theme="bootstrap"
        />
      </div>
    </div>
  );
};

export default Blogs;
