import React from "react";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";

const NavBar = () => {
  <style></style>;
  return (
    <div>
      <nav
        class="navbar navbar-expand-lg navbar-light  sticky-top "
        style={{ zIndex: 9999 }}
        id="nav-main"
      >
        <div class="container d-flex flex-wrap justify-content-between">
          <a href="/" class="navbar-brand"></a>
          <button
            class="navbar-toggler"
            data-target="#navigation"
            data-control="navigation"
            data-toggle="collapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navigation">
            <ul
              class="nav navbar-nav pull-lg-right ml-auto align-items-center"
              data-toggle="collapse"
              data-target="#navigation"
            >
              <li class="nav-item">
                <Link to="/promexa">
                  <span href="#" className={styles.textStyle}>
                    Promexa
                  </span>
                </Link>
              </li>

              <li class="nav-item sub-menu-parent ">
                <a class="nav-link page-scroll drop-down" href="#">
                  Company
                </a>
                <ul class="sub-menu">
                  <Link to="/about">
                    <li class="">
                      <a href="#" class="">
                        About Us
                      </a>
                    </li>
                  </Link>
                  <Link to="/ourteam">
                    <li class="">
                      <a href="#" class="">
                        Our Team
                      </a>
                    </li>
                  </Link>
                </ul>
              </li>
              <li class="nav-item">
                <Link to="/career">
                  <span href="#" className={styles.textStyle}>
                    Careers
                  </span>
                </Link>
              </li>
              <li class="nav-item ">
                <Link to="/blogs">
                  <span href="#" className={styles.textStyle}>
                    Blogs
                  </span>
                </Link>
              </li>
              <li class="nav-item ">
                <Link to="/contact">
                  <span href="#" className={styles.textStyle}>
                    Contact Us
                  </span>
                </Link>
              </li>

              <li class="">
                <a
                  href="https://promexa.cerebranium.com/#/"
                  target="_blank"
                  class="nav-link btn-all btn-trial"
                >
                  Get a free trial
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
