import React from 'react';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Career from './Components/Career';
import Blogs from './Components/Blogs';
import OurTeam from './Components/OurTeam';
import Promexa from './Components/Promexa';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';
import BlogDetail from './Components/BlogDetail'
import {Switch,Route} from'react-router-dom';
import EventsCsr from './Components/EventsCsr';
import Imprint from './Components/Imprint';
import Cookie from './Components/Cookie';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/about' component={About}/>
        <Route exact path='/contact' component={Contact}/>
        <Route exact path='/career' component={Career}/>
        <Route exact path='/blogs' component={Blogs}/>
        <Route exact path='/ourteam' component={OurTeam}/>
        <Route exact path='/promexa' component={Promexa}/>
        <Route exact path='/blog-detail' component={BlogDetail}/>
        <Route exact path='/eventcsr' component={EventsCsr}/>
        <Route exact path='/imprint' component={Imprint}/>
        <Route exact path='/cookie_policy' component={Cookie}/>
      </Switch>
    <Footer/>
    </div>
  );
}

export default App;
