import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../api";
const OurTeam = () => {
  const [teamData, setTeamData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    api.teamCrud.getAll().then((res) => {
      if (res.status === 1) {
        console.log(res);
        setTeamData(res.data);
      } else {
        console.log(res);
      }
    });
  }, []);

  return (
    <div>
      {/*<!-- team page hero Start  --> */}
      <div class="team-hero">
        <div class="team-hero-inner">
          <div class="page-heading ">
            <div class="sec-title-big sec-margin">
              <h2 class="text-center">
                Meet The Minds <br />
                Shaping Our Industry
              </h2>
            </div>
            <p class="text-center">
              The pulse of our company is it's people. Here are the people that{" "}
              <br />
              make the company.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- team page hero End  -->
		  <!-- team list Start  --> */}
      <div class="team-list-container pb-0">
        <div class="container">
          <div class="team-heading">
            <h2> Team</h2>
          </div>
          <div class="team-list-inner row">
            {teamData.map((item) => {
              return (
                <div class="col-lg-6 col-md-12">
                  <div class="team-box d-flex align-items-center ">
                    <div class="team-img">
                      <img src={item.image_url} alt="image" class="img-fluid" />
                      <div class="team-img-hover">
                        <h4>{item.name}</h4>
                        <p>{item.designation}</p>
                        <ul class="team-social d-flex align-items-center justify-content-center">
                          {item.instagram_link && (
                            <li>
                              <a href={item.instagram_link} target="_self">
                                <i class="fa fa-instagram"></i>
                              </a>
                            </li>
                          )}
                          {item.linkedin_link && (
                            <li>
                              <a href={item.linkedin_link} target="_self">
                                <i class="fa fa-linkedin"></i>
                              </a>
                            </li>
                          )}
                          {item.facebook_link && (
                            <li>
                              <a href={item.facebook_link} target="_self">
                                <i class="fa fa-facebook"></i>
                              </a>
                            </li>
                          )}
                          {item.twitter_link && (
                            <li>
                              <a href={item.twitter_link} target="_self">
                                <i class="fa fa-twitter"></i>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div class="team-des">
                      <p style={{ textAlign: "start" }}>
                        {item.short_description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <!-- team list End  -->
		   <!-- team list Start  --> */}
      {/* <!-- team list End  -->
		  <!--join section start--> */}
      <div class="join-main-container">
        <div class="container">
          <div class="join-inner d-flex align-items-center justify-content-between">
            <div class="join-left">
              <div class="page-heading ">
                <div class="sec-title-big sec-margin">
                  <h2 class="">Want to join?</h2>
                </div>
                <p class="color-black" style={{ textAlign: "start" }}>
                  Are you interested to join our team? Check out our <br />
                  job openings and apply on your suitable role.
                </p>
              </div>
            </div>
            <div class="join-right">
              <Link to="/career">
                <button class="btn-book w-auto">Check Job Openings</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
