import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div class="footer-inner">
        <div class="container">
          <div class="row">
            <div class="foot-sec col-lg-5 col-md-6">
              <div class="foot-logo">
                <img
                  src="assets/images/logo_Cerebranium.png"
                  alt="logo"
                  class="img-fluid"
                  style={{ width: "25%" }}
                />
              </div>
              <div class="foot-para">
                <p style={{ maxWidth: 350 }}>
                  We help education providers with achieving high levels of
                  Learner Engagement, Employability, and Enrolment with
                  Data-driven Governance.
                </p>
              </div>
              <div class="copyright">© Cerebranium</div>
            </div>
            <div class="foot-sec col-lg-3 col-md-6">
              <ul class="foot-list ">
                <li class="foot-list-bold">CONTACT</li>
                <li>
                  Cerebranium GmbH <br /> Pappelallee 78/79, 10437 Berlin,
                  Germany
                </li>
                <li>
                  <a href="#" class="mb-0">
                    welcome@cerebranium.com
                  </a>
                </li>
                {/* <li>
						<a href="#" class="mb-0">+1 800 726 915273</a>
					</li>	 */}
              </ul>
            </div>
            <div class="foot-sec col-lg-2 col-md-6">
              <ul class="foot-list ">
                <li class="foot-list-bold">COMPANY</li>
                <Link to="/about">
                  <li>
                    <a href="#">About Us</a>
                  </li>
                </Link>
                <br />

                <Link to="/ourteam">
                  <li>
                    <a href="#" class="mb-0">
                      Our Team
                    </a>
                  </li>
                </Link>
                <br />
                <Link to="/eventcsr">
                  <li>
                    <a href="#" class="mb-0">
                      Events and CSR
                    </a>
                  </li>
                </Link>
                <br />
                <Link to="/imprint">
                  <li>
                    <a href="#" class="mb-0">
                      Imprint
                    </a>
                  </li>
                </Link>
                <br />
                <Link to="/cookie_policy">
                  <li>
                    <a href="#" class="mb-0">
                      Cookies Policy
                    </a>
                  </li>
                </Link>
              </ul>
            </div>
            <div class="foot-sec col-lg-2 col-md-6">
              <ul class="foot-list ">
                <li class="foot-list-bold">PRODUCT</li>
                <Link to="/promexa">
                  <li>
                    <a href="#">Promexa</a>
                  </li>
                </Link>
                <br />
                <Link to="/blogs">
                  <li>
                    <a href="#">Blogs</a>
                  </li>
                </Link>
                <br />
                <Link to="/career">
                  <li>
                    <a href="#">Career</a>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
