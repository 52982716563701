import React, { useEffect, useState,useRef } from 'react';
import api from '../api';
import moment from 'moment';
import { useHistory } from 'react-router';

const BlogDetail = (props) => {
  const iFrameRef = useRef(null)

  const [singleBlog, setSingleBlog] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [content, setContent] = useState('')
  const [height, setHeight] = React.useState("0px");
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    getRelatedBlogs();
    getSingleBlog();
  }, [])

  const getSingleBlog = () => {
    if (props.location.state && props.location.state.status);
    api.blogCrud.getOne(props.location.state.status).
      then(result => {
        if (result.status === 1) {
          setSingleBlog(result.data[0])
          setContent(`<style>@font-face {
              font-family: Flink Medium;
              src: url("./assets/fonts/flink-medium.otf") format("OpenType");
              }
              @font-face {
              font-family: Flink Regular;
              src: url("./assets/fonts/flink-regular.otf") format("OpenType");
            }
            </style>
            <div style="font-family: Flink Regular !important">${result.data[0].long_description}</div>`)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getRelatedBlogs = () => {
    api.blogCrud.getAll().then((res) => {
      if (res.status === 1) {
        console.log(res)
        setBlogData(res.data.reverse())
      }
      else {
        console.log(res)
      }
    })
  }

  const onLoad = () => {
    const h = iFrameRef.current.contentWindow.document.body.scrollHeight + 50
    setHeight(h + "px");
    const body = iFrameRef.current.contentWindow.document.body
    console.log(body)
    body.style.fontFamily= "Flink Regular !important"
    console.log(height)
  };


  return (<div>
    <div class="blog-page-hero">

      <div class="blog-detail-inner">
        <div class="container">
          <div class="page-heading ">
            <div class="sec-title-big sec-margin"><h2 class="text-center">{singleBlog.blog_title}</h2></div>
            <div class="blog-bottom d-flex align-items-center justify-content-center m-5">
              <div class="blog-head-info d-flex align-items-center mr-3">
                <svg className="mr-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 8.5C9.69223 8.5 10.3689 8.29473 10.9445 7.91015C11.5201 7.52556 11.9687 6.97894 12.2336 6.33939C12.4985 5.69985 12.5678 4.99612 12.4327 4.31719C12.2977 3.63825 11.9644 3.01461 11.4749 2.52513C10.9854 2.03564 10.3618 1.7023 9.68282 1.56725C9.00388 1.4322 8.30015 1.50152 7.66061 1.76642C7.02107 2.03133 6.47444 2.47993 6.08986 3.05551C5.70527 3.63108 5.5 4.30777 5.5 5C5.5 5.92826 5.86875 6.8185 6.52513 7.47487C7.1815 8.13125 8.07174 8.5 9 8.5ZM9 2.5C9.49445 2.5 9.9778 2.64662 10.3889 2.92133C10.8 3.19603 11.1205 3.58648 11.3097 4.04329C11.4989 4.50011 11.5484 5.00277 11.452 5.48773C11.3555 5.97268 11.1174 6.41814 10.7678 6.76777C10.4181 7.1174 9.97268 7.3555 9.48773 7.45196C9.00277 7.54843 8.50011 7.49892 8.04329 7.3097C7.58648 7.12048 7.19603 6.80005 6.92133 6.38893C6.64662 5.9778 6.5 5.49445 6.5 5C6.5 4.33696 6.76339 3.70108 7.23223 3.23223C7.70107 2.76339 8.33696 2.5 9 2.5Z" fill="#04B8AB" />
                  <path d="M15.235 12.1849C14.4332 11.3374 13.4669 10.6624 12.3953 10.201C11.3237 9.73966 10.1692 9.50171 9.0025 9.50171C7.83579 9.50171 6.68135 9.73966 5.60973 10.201C4.53811 10.6624 3.57185 11.3374 2.77 12.1849C2.59616 12.3706 2.49961 12.6155 2.5 12.8699V15.4999C2.5 15.7651 2.60536 16.0195 2.79289 16.207C2.98043 16.3945 3.23478 16.4999 3.5 16.4999H14.5C14.7652 16.4999 15.0196 16.3945 15.2071 16.207C15.3946 16.0195 15.5 15.7651 15.5 15.4999V12.8699C15.5018 12.6162 15.407 12.3714 15.235 12.1849ZM14.5 15.4999H3.5V12.8649C4.20862 12.1187 5.06165 11.5246 6.00718 11.1185C6.95271 10.7125 7.97098 10.5031 9 10.5031C10.029 10.5031 11.0473 10.7125 11.9928 11.1185C12.9384 11.5246 13.7914 12.1187 14.5 12.8649V15.4999Z" fill="#04B8AB" />
                </svg>
                {singleBlog.author_name}
              </div>
              <div class="blog-head-info d-flex align-items-center mr-3">
                <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8C14 4.6875 11.3125 2 8 2Z" stroke="#04B8AB" stroke-miterlimit="10" />
                  <path d="M8 4V8.5H11" stroke="#04B8AB" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {moment(singleBlog.date).format("DD-MM-YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-detail-main ">
        <div class="container">
          <div class="blog-description">
            <div class="blog-detail-img d-flex justify-content-center">
              <img src={singleBlog.blog_image} alt="oimage" class="img-fluid" />
            </div>
            <div class="blog-detail-content mt-5">
              <div class="blog-content" >
              
                <iframe ref={iFrameRef} scrolling="no" onLoad={onLoad} height={height} style={{fontFamily: "Flink Regular",width:"100%",border: "none"}} srcdoc={content}></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- blog-detail  End  -->
	 
	  <!--article-bottom section Start  --> */}
    <div class="article-bottom-section-container section-bottom">
      <div class="container mt-5">
        <div class="sec-title">
          <h2>Related blogs</h2>
        </div>
        <div class="article-inner row">
          {blogData.slice(0, 3).map((data, idx) => {
            return (
              <div class="col-lg-4 col-md-12 article-card mb-0 ">
                <div class="article-img" style={{ cursor: 'pointer' }} onClick={() => {
                        // console.log("DATA BLOG ", data)
                        history.push({
                          pathname: "/blog-detail",
                          
                          state: {
                            status: data.blogs_id
                          }
                        })
                        window.location.reload()
                        
                          
                      }}>
                  <img src={data.blog_image} class="img-fluid" alt="image" />
                </div>
                <div class="article-des" style={{ cursor: 'pointer' }} onClick={() => {
                        // console.log("DATA BLOG ", data)
                        history.push({
                          pathname: "/blog-detail",
                          state: {
                            status: data.blogs_id
                          }
                        })
                         window.location.reload()
                         
                      }}>
                  <span class="article-date">
                    {moment(data.date).format("DD-MM-YYYY")}
                  </span>
                  <h4 class="article-title">
                    {data.blog_title}
                  </h4>
                  <p className="mb-3" style={{ lineHeight: 'revert' }}>{data.short_description} </p>
                </div>
              </div>
            )
          })}
          {/* <div class="col-lg-4 col-md-12 article-card mb-0">
            <div class="article-img">
              <img src="assets/images/article-img2.png" class="img-fluid" alt="image" />
            </div>
            <div class="article-des">
              <span class="article-date">March 26, 2021</span>
              <h4 class="article-title">
                How is the pandemic affecting the way people work?
              </h4>
            </div>
          </div> */}
          {/* <div class="col-lg-4 col-md-12 article-card mb-0">
            <div class="article-img">
              <img src="assets/images/article-img9.png" class="img-fluid" alt="image" />
            </div>
            <div class="article-des">
              <span class="article-date">March 19, 2021</span>
              <h4 class="article-title">
                The parent’s balancing act: using the word ‘No’
              </h4>
            </div>
          </div> */}
        </div>

      </div>
    </div>

  </div>);
}

export default BlogDetail;