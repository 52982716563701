import React, { useEffect, useState } from 'react';
import api from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [desc, setDesc] = useState('');
	const [subject, setSubject] = useState('')
	const addEnquiry = (e) => {
		e.preventDefault()
		if(!name || !email){
			alert("Please enter all required fields")
			return
		}		
		let data = {
			full_name: name,
			email: email,
			mobile_no: mobile,
			description: desc,
			subject: subject
		}
		api.enquiryCrud.addEnquiry(data).then((result) => {
			console.log("Enquiry Added ====> ", result)
			if (result.status === 1) {
				console.log(result.message);
				toast.success('Thank You! We have received your details. We shall be in touch with you shortly')
				setTimeout(() => {
					setName("");
					setEmail("");
					setMobile("");
					setDesc("");
					setSubject('')
				}, 3500);
			} else {

			}
		}).catch(error => {
			console.log(error)
		})

	}


	return (<div>
		{/* <!--Navigation End -->
      <!-- contact page hero Start  --> */}
		<div className="contact-page-hero">

			<div className="contact-inner">
				<div className="page-heading ">
					<div className="sec-title-big sec-margin"><h2 className="text-center">Contact and Help Center</h2></div>
					<p className="text-center">
						We are an industry-leading company that values honesty, integrity, and efficiency. <br /> Feel free to contact us if you have any queries.
					</p>
				</div>
			</div>
			<div className="contact-form-inner d-flex justify-content-end">
				<div className="contact-box">
					<div className="contact-icon">
						<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_362:282)">
								<path d="M26.016 22.3255C27.0436 21.3838 27.8679 20.242 28.438 18.9701C29.0082 17.6982 29.3122 16.3232 29.3315 14.9295C29.3315 8.61735 23.0831 3.45288 15.3045 3.45288C7.52595 3.45288 1.27759 8.61735 1.27759 14.9295C1.27759 21.2416 7.52595 26.4061 15.3045 26.4061C16.6337 26.3871 17.9577 26.2376 19.2576 25.9598L26.7811 28.9564L26.016 22.3255Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M8.92859 12.3792H21.6804" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M8.92859 17.4797H16.5797" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
							</g>
							<defs>
								<clipPath id="clip0_362:282">
									<rect width="30.6042" height="30.6042" fill="white" transform="translate(0.00244141 0.902588)" />
								</clipPath>
							</defs>
						</svg>

					</div>
					<div className="contact-heading">
						<h3>Get in touch</h3>
						<p>For general queries please fill the form below.</p>
					</div>
					<div className="contact-form-content">
						<form className="contact-form">
							<div className="form-input">
								<input type="text" name="name" value={name} placeholder="Your name*" onChange={(e) => { setName(e.target.value) }} />
							</div>
							<div className="form-input">
								<input type="text" name="email" value={email} placeholder="Email address*" onChange={(e) => { setEmail(e.target.value) }} />
							</div>
							<div className="form-input">
								<input type="text" name="phone" value={mobile} placeholder="Phone" onChange={(e) => { setMobile(e.target.value) }} />
							</div>
							<div className="form-input">
								<input type="text" name="subject" value={subject} placeholder="Subject" onChange={(e) => { setSubject(e.target.value) }} />
							</div>
							<div className="form-input">
								<input type="text" name="msg" value={desc} placeholder="Your message" onChange={(e) => { setDesc(e.target.value) }} />
							</div>
							<button className="btn-con" onClick={(e) => addEnquiry(e)}>Submit</button>
							<ToastContainer autoClose={5000} hideProgressBar={true} />
							{/* Same as */}
						</form>
					</div>
				</div>
			</div>

		</div>
		{/* <!-- contact hero End  -->
		 <!-- faq Start  --> */}
		<div className="faq-main-container section">
			<div className="container">
				<div className="sec-heading sec-margin">
					<div className="sec-title-big "><h2 className="text-center">Frequently Asked Question</h2></div>
				</div>
				<div className="faq-inner row align-items-center">
					<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>1. What is the network/bandwidth requirement to take an exam?</h4>
							<p>Answer. The students don’t require any internet connection while taking an exam/assessment on Promexa. The only time students require an internet connection is before the exam starts or while uploading it. During this process too, Promexa reduces the consumption of internet by 50%</p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>2. How can we observe the complete environment of a student while he takes an exam?</h4>
							<p>Answer. Promexa supports 360 Degree proctoring. Being a mobile-first platform, it uses both front and rear cameras to observe the entire surroundings of students, making the exam experience of students fair and reliable.</p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>3. What is an AI-proctored exam? How reliable is it?</h4>
							<p>Answer. AI proctoring on Promexa enables the institutions to monitor students without the requirement of human supervision. It generates video feeds of students and uses AI to analyze their behavior while they take an exam. </p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>4. Do we need to go through the entire video of students to determine whether they cheated or not?</h4>
							<p>Answer. Orionis Engine generates AI-driven insights and a Cheating Prediction score based on the activity of students. It lets you determine whether students cheated or not without going through their entire video. </p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>5. We have a large batch of students. How many students can simultaneously take an exam on Promexa?</h4>
							<p>Answer. Up to 500 hundred students can simultaneously take exams/assessments on Promexa. Moreover, exams can be scheduled for multiple batches at once, with each batch holding up to 500 students. </p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>6. What is the difference between Exam Window and Exam Duration?</h4>
							<p>Answer. Exam Window is the time within which the students can start giving the exam, whereas, Exam Duration is the time during which the students can take the exam. </p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>7. There are multiple subjects from which I create an exam/assessment. Is multiple subject management possible on Promexa?</h4>
							<p>Answer. With one-click subject management on Promexa, you can stay efficient and organized while managing multiple subjects</p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>8. What question types does Promexa support?</h4>
							<p>Answer. Promexa supports Subjective, Numerical, and MCQ questions, which gives an examiner the liberty to choose from numerous options.</p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>9. Can the students upload handwritten answers? </h4>
							<p>Answer. Yes, with the Scan and Upload feature, students can upload handwritten answers without the need for a different device.</p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>10. Can you customize the exam report as per the institution’s requirements?</h4>
							<p>Answer. Examiners can get a detailed report on student statistics on Promexa. In addition, we also customize exam reports as per the requirements of an institution. </p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>11.	Once the students complete an exam, can we restrict them from looking at the results on Promexa?</h4>
							<p>Answer. Yes, the students can be restricted from seeing the result after they complete an exam. The results can be made visible as per the institution’s requirements. </p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>12.	Once we supervise the exam, is the data stored for later reference, or is it deleted?</h4>
							<p>Answer. The exam data is stored on Promexa for 90 days. This period can be extended as per an institution’s requirement. </p>
						</div>
					</div>
						<div className="col-lg-6 col-md-12">
						<div className="faq-main">
							<h4>13.	Can we declare results for the entire batch at once?</h4>
							<p>Answer. Yes, with bulk supervision, an examiner can easily declare results for all the students at once. </p>
						</div>
					</div>

				</div>
				<a href="#" className="con-link">
					Didn’t find your answer? <span>Contact us here</span>
				</a>
			</div>
		</div>
	</div>);
}

export default Contact;